import { Product } from "../../product/product.types";
import { Item } from "../types/common.types";

export interface ProductCardSettings {
  hasStock: boolean;
  showFlavors: boolean;
  showSizes: boolean;
  showContentInfo: boolean;
  showAttributes: boolean;
  isSummary: boolean;
  isCardInOrderPage?: boolean;
}

export interface ProductCard {
  orderId?: Partial<number>;
  product: Partial<Product>;
  settings: Partial<ProductCardSettings>;
}

export interface Category extends Item {
  minimumSpend: number;
}

export enum CardTypes {
  main = 'main',
  checkAddress = 'checkAddress',
  notify = 'notify',
  notified = 'notified',
  subscribe = 'subscribe',
  summary = 'summary',
  preferences = 'preferences'
}

export type FavOrDislike = {
  isDisliked: boolean;
  id: number
  value: boolean
};
