import { Injectable, inject, signal } from "@angular/core";
import { ApiService } from "../../../shared/api.service";
import { filter, finalize, tap } from "rxjs";
import { NotificationService } from "../../../shared/notification/notification.service";
import { Subscription, UpdateSubscriptionPayload, ChangeType, SubscriptionPayload, UpdateAllSubscriptionsPayload } from './subscriptions.types';
import { formatDateToReadableString } from "../../../shared/utils/formatting";
import { RequestHandlerParams } from "../../../shared/types/api-service.types";
import { SignalsStoreService } from "../../../shared/signals-store.service";
import { ApiResponse } from "../../../shared/common/types";
import { OrderService } from "../../../shared/order.service";
import { SettingsService } from "../../settings.service";
import { ModalContentService } from "../../../shared/modal-content/modal-content.service";
import { ModalContentTypes } from "../../../shared/constants/modal-content-types";
import { UnableToCancelSubscription } from "./subscription.constants";


@Injectable()
export class SubscriptionsService {

    private apiService = inject(ApiService);
    private notificationService = inject(NotificationService);
    private signalStoreService = inject(SignalsStoreService);
    private orderService = inject(OrderService);
    #settingsService = inject(SettingsService);
    #modalContentService = inject(ModalContentService);

    globalPauseDate = signal({
        start: '',
        end: ''
    })

    private endpoints = {
        base: '/account/subscriptions'
    }

    get() {
        const params: RequestHandlerParams = {
            method: 'GET',
            endpoint: this.endpoints.base,
            showErrorMessage: false,
            apiV3: true
        }

        this.globalPauseDate.set({
            start: '',
            end: ''
        });

        this.signalStoreService.subscriptions.set([]);

        this.apiService.handleRequest<ApiResponse<Subscription[]>>(params).pipe(
            filter(response => !!response?.data?.length),
            tap(response => {
                const data = this.setUpSubscriptionData(response.data);
                this.signalStoreService.subscriptions.set(data)
            })
        ).subscribe();
    }

    create(subscription: SubscriptionPayload) {
        const params: RequestHandlerParams = {
            method: 'POST',
            body: subscription,
            endpoint: this.endpoints.base,
            apiV3: true
        }

        this.globalPauseDate.set({
            start: '',
            end: ''
        });

        return this.apiService.handleRequest<ApiResponse<Subscription[]>>(params).pipe(
            filter(response => !!response?.data?.length),
            tap(response => this.manageSubscriptionResponse(response, false))
        )
    }

    update(id: number, subscription: Partial<UpdateSubscriptionPayload>, showDefaultMessage: boolean = true) {
        const params: RequestHandlerParams = {
            method: 'PATCH',
            endpoint: `${this.endpoints.base}/${id}`,
            body: subscription,
            apiV3: true
        };

        this.globalPauseDate.set({
            start: '',
            end: ''
        });

        return this.apiService.handleRequest<ApiResponse<Subscription[]>>(params).pipe(
            filter(response => !!response?.data?.length),
            tap(response => this.manageSubscriptionResponse(response, showDefaultMessage)),
            tap(() => {
                if (subscription.skip)
                    this.orderService.getOrder()
            })
        )
    }

    cancel(id: number) {
        const params: RequestHandlerParams = {
            method: 'DELETE',
            endpoint: `${this.endpoints.base}/${id}`,
            apiV3: true,
            returnError: true,
            showErrorMessage: false
        };

        this.globalPauseDate.set({
            start: '',
            end: ''
        });

        return this.apiService.handleRequest<ApiResponse<Subscription[] | any>>(params).pipe(
            tap(() => this.orderService.getOrder()),
            tap(response => {
                this.manageSubscriptionResponse(response)
            })
        ).subscribe();
    }

    updateAll(body: Partial<UpdateAllSubscriptionsPayload>) {
        this.#settingsService.setIsWaitingForAPIResponse(true);
        const params: RequestHandlerParams = {
            method: 'PATCH',
            endpoint: `${this.endpoints.base}`,
            body,
            apiV3: true
        };

        this.globalPauseDate.set({
            start: '',
            end: ''
        });

        this.apiService.handleRequest<ApiResponse<Subscription[]>>(params).pipe(
            filter(response => !!response?.data?.length),
            tap(response => this.manageSubscriptionResponse(response)),
            tap(() => this.orderService.getOrder()),
            finalize(() => this.#settingsService.setIsWaitingForAPIResponse(false))
        ).subscribe();
    }

    private manageSubscriptionResponse(response: ApiResponse<Subscription[] | any>, showDefaultMessage: boolean = true) {
        if (response.data.minimumSubscriptionRequired) {
            this.#modalContentService.openModal(ModalContentTypes.CONFIRMATION, {
                ...UnableToCancelSubscription,
                confirmButtonText: 'I Understand'
            });
            return;
        }

        if (showDefaultMessage)
            this.notificationService.show({ text: response.message, type: 'success' });
        const data = this.setUpSubscriptionData(response.data);
        this.signalStoreService.subscriptions.set(data)
    }

    private setUpSubscriptionData(sub: Subscription[]) {
        // TODO: Review this 'fix':
        const data = JSON.parse(JSON.stringify(sub));
        const response = data.map((e: Subscription, index: number) => {
            if (e.skipDate) {
                const formattedDate = (formatDateToReadableString(e.skipDate));
                e.skipDate = `${formattedDate.dayName}, ${formattedDate.month} ${formattedDate.day}`;
            }

            if (e.pauseDate?.startDate) {
                if (index === 0) {
                    const pause: any = { ...e.pauseDate };
                    this.setUpGlobalPauseDates(pause);
                }
                e.pauseDate.startDate = e.pauseDate.startDate ? formatDateToReadableString(e.pauseDate.startDate).readableString : '';
                e.pauseDate.endDate = e.pauseDate.endDate ? formatDateToReadableString(e.pauseDate.endDate).readableString : '';
                e.pauseDate.nextDeliveryAfterPause = e.pauseDate.nextDeliveryAfterPause ? formatDateToReadableString(e.pauseDate.nextDeliveryAfterPause).readableString : ''
            }

            const formattedDate = formatDateToReadableString(e.nextDelivery);
            e.shownNextDelivery = `${formattedDate.monthNumber}/${formattedDate.dayNumber}/${formattedDate.year}`;
            e.quantity = e.product.quantity;
            // Store prvious frequency and quantity
            e.previous = {
                quantity: JSON.parse(JSON.stringify(e.quantity)),
                frequencyId: JSON.parse(JSON.stringify(e.frequency))
            };

            return e;
        })

        return response;
    }

    private setUpGlobalPauseDates(pause: any) {
        const formatteStartdDate = formatDateToReadableString(pause.startDate);
        const formattedEnddDate = formatDateToReadableString(pause.endDate);
        this.globalPauseDate.set({
            start: `${formatteStartdDate.monthNumber}/${formatteStartdDate.dayNumber}/${formatteStartdDate.year}`,
            end: `${formattedEnddDate.monthNumber}/${formattedEnddDate.dayNumber}/${formattedEnddDate.year}`
        });
    }

    public formatDate(date: string): string {
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    }

    restorePreviousValues(subscription: Subscription) {
        if (subscription.previous?.frequencyId)
            subscription.frequency = subscription.previous?.frequencyId;
        if (subscription.previous?.quantity)
            subscription.quantity = subscription.previous?.quantity;
    }
}